import React from 'react'
import { graphql } from 'gatsby'
import Section16 from '../components/sections/section16'
import CommonFooter from '../components/footer/commonFooter'
import SiteNavigation from '../components/header/siteNavigation'
import SvgIndustries from '../illustrations/Industries/Industries'
import Seo from '../components/seo/seo'
import { useTranslation } from 'react-i18next'

const Industries = ({ data }) => {
  const { t } = useTranslation();
  return (
  <>
    <Seo
      title={t('industries.seoTitle')}
      description={t('industries.seoDescription')}
      seoImage={data.seoImage.childImageSharp.resize.src}
    />
    <SiteNavigation />
    <Section16
      h1="sì"
      gradient="maingrad"
      textColor="text-white"
      sectionTitle={t('industries.title')}
      sectionDesc={t('industries.description')}
      features={[1, 2, 3, 4, 5, 6]}
      featureTitle={[
        <>{t('industries.industry1')}</>,
        <>{t('industries.industry2')}</>,
        <>{t('industries.industry3')}</>,
        <>{t('industries.industry4')}</>,
        <>{t('industries.industry5')}</>,
        <>{t('industries.industry6')}</>,
      ]}
      featureDesc={[
        <>{t('industries.industry1longdesc')}</>,
        <>{t('industries.industry2longdesc')}</>,
        <>{t('industries.industry3longdesc')}</>,
        <>{t('industries.industry4longdesc')}</>,
        <>{t('industries.industry5longdesc')}</>,
        <>{t('industries.industry6longdesc')}</>,
      ]}
      illustration={<SvgIndustries />}
    />
    <CommonFooter />
  </>
)
}

export default Industries

export const manufacturingQuery = graphql`
  query {
    seoImage: file(
      relativePath: { eq: "manufacturing/SMT_Assembly_PCBA.jpg" }
    ) {
      childImageSharp {
        resize(width: 900, quality: 90) {
          src
        }
      }
    }
  }
`
